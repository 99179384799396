<template>
    <div id="yt-video" class="block w-full h-full" />

    <teleport to="body">
        <dialog v-show="isReviewOpen" class="rating-popup">
            <div class="w-full my-auto p-2 md:p-8">
                <div class="container">
                    <div class="max-w-xl mx-auto">
                        <div class="block relative p-4 md:p-8 bg-gray-100 text-black">
                            <form
                                ref="formEl"
                                v-click-outside="vcoConfig"
                                method="post"
                                :data-fui-form="formieForm?.configJson"
                            >
                                <input type="hidden" name="action" value="formie/submissions/submit" />
                                <input type="hidden" name="handle" value="ratings" />
                                <input type="hidden" name="fields[video][]" :value="videoId" />
                                <input type="hidden" :name="formieForm?.csrfToken.name" :value="formieForm?.csrfToken.value" />

                                <div class="text-right">
                                    <button type="button" @click.prevent="isReviewOpen = false">
                                        <close-icon class="block w-8 h-8" />

                                        <span class="sr-only">Close</span>
                                    </button>
                                </div>

                                <div class="richtext text-center text-gray-600 mt-4" v-html="ratingMessage" />

                                <h2 class="h4 text-center text-gray-600 mt-4">How Would You Rate This Content?</h2>

                                <ul class="ratings">
                                    <li v-for="item in ratings" :key="item.value">
                                        <input
                                            :id="`rating-${item.value}`"
                                            v-model="rating"
                                            type="radio"
                                            name="fields[rating]"
                                            :value="item.value"
                                            required
                                        />
                                        <label :for="`rating-${item.value}`">
                                            <span class="ratings__button">
                                                <component :is="item.component" class="block w-full h-auto" />
                                            </span>

                                            <span :style="{ color: item.colour }" class="ratings__label">{{ item.label }}</span>
                                        </label>
                                    </li>
                                </ul>

                                <div class="mt-8">
                                    <textarea
                                        v-model="feedback"
                                        class="fui-input !p-2 !bg-white"
                                        placeholder="Additional thoughts or feedback?"
                                        name="fields[feedback]"
                                    />
                                </div>

                                <div class="formie-recaptcha-placeholder" data-recaptcha-placeholder />

                                <div class="mt-4 text-right">
                                    <button
                                        class="btn btn--primary"
                                        type="submit"
                                        :disabled="!rating"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    </teleport>
</template>

<script>
    import CloseIcon from '../../img/icons/xmark-thin.svg';
    import Rate1 from '../../img/icons/review/1.svg';
    import Rate2 from '../../img/icons/review/2.svg';
    import Rate3 from '../../img/icons/review/3.svg';
    import Rate4 from '../../img/icons/review/4.svg';
    import Rate5 from '../../img/icons/review/5.svg';
    import { GET_RATING_FORM_QUERY } from '@/js/graphql/forms';
    import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';

    export default {
        components: {
            CloseIcon,
        },

        props: {
            videoId: {
                type: String,
                required: true,
            },
            ytId: {
                type: String,
                required: true,
            },
        },

        emits: ['ended'],

        data() {
            return {
                formieForm: null,
                formieInstance: null,
                player: null,
                isReviewOpen: false,
                ratingMessage: window.i18n.ratingMessage,
                vcoConfig: {
                    events: ['mousedown'],
                    handler: () => this.isReviewOpen = false,
                },
                ratings: [
                    {
                        component: Rate5,
                        colour: '#1d723f',
                        label: 'Very Good',
                        value: 5,
                    },
                    {
                        component: Rate4,
                        colour: '#43af64',
                        label: 'Good',
                        value: 4,
                    },
                    {
                        component: Rate3,
                        colour: '#e5b92c',
                        label: 'Medium',
                        value: 3,
                    },
                    {
                        component: Rate2,
                        colour: '#c94545',
                        label: 'Poor',
                        value: 2,
                    },
                    {
                        component: Rate1,
                        colour: '#8e2834',
                        label: 'Very Bad',
                        value: 1,
                    },
                ],
                rating: null,
                feedback: '',
            };
        },

        watch: {
            isReviewOpen(value) {
                if (value) {
                    this.$nextTick(() => {
                        this.initForm();
                    });
                } else if (this.formieInstance && this.$refs.formEl) {
                    this.$refs.formEl.removeEventListener('onAfterFormieSubmit', this.onAfterFormieSubmit);
                    this.formieInstance.destroyForm(this.$refs.formEl);
                }
            },
        },

        mounted() {
            this.initVideo();
        },

        apollo: {
            formieForm: {
                query: GET_RATING_FORM_QUERY,
            },
        },

        methods: {
            initVideo() {
                if (typeof YT === 'undefined') {
                    setTimeout(() => {
                        this.initVideo();
                    }, 100);

                    return;
                }

                this.player = new YT.Player('yt-video', {
                    videoId: this.ytId,
                    playerVars: { autoplay: '1' },
                    events: {
                        onStateChange: this.onStateChange,
                    },
                });
            },
            initForm() {
                this.formieInstance = new Formie();
                this.formieInstance.initForm(this.$refs.formEl);
                this.$refs.formEl.addEventListener('onAfterFormieSubmit', this.onAfterFormieSubmit);
            },
            onStateChange(event) {
                if (event.data === YT.PlayerState.ENDED) {
                    this.isReviewOpen = true;
                    this.$emit('ended');
                }
            },
            onAfterFormieSubmit(e) {
                e.preventDefault();

                this.isReviewOpen = false;
            },
        },
    };
</script>

<style lang="postcss">
    .rating-popup {
        @apply fixed flex left-0 top-0 p-0 w-screen h-screen overflow-auto bg-black/50;

        z-index: 999999;
    }

    .ratings {
        @apply flex flex-wrap justify-between mt-8 -mx-0.5 md:-mx-2;

        li {
            @apply block px-0.5 md:px-2 w-1/5;
        }

        input {
            @apply sr-only;
        }

        label {
            @apply block h-full text-center cursor-pointer;
        }

        &__button {
            @apply block p-2 bg-white rounded-lg border-2 border-gray-200;
        }

        &__label {
            @apply block mt-2 leading-tight font-semibold text-xs md:text-base;
        }

        input:checked + label &__button {
            @apply border-gray-600;
        }
    }
</style>
